.printView {
  max-width: 35%;
  width: 35%;
}

@media print {
  @page {
    margin: 0;
  }
  body,
  html {
    margin: 0;
    padding: 0;
  }
  .printView {
    max-width: 100% !important;
    width: 100% !important;
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .printView {
    max-width: 100% !important;
    width: 100% !important;
    display: none;
  }
}
