.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
    font-size: 10px;
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
    font-size: 12px;
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
    font-size: 14px;
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    font-size: 18px;
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
    font-size: 24px;
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
    font-size: 36px;
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
    font-size: 48px;
  }
  